// Action Types
export const SET_LICENSE_PLATE_INFO = 'SET_LICENSE_PLATE_INFO';
export const CLEAR_CURRENT_LICENSE_PLATE = 'CLEAR_CURRENT_LICENSE_PLATE';

const initialState = {
  currentLicensePlate: null,
};

const currentLicensePlateReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_LICENSE_PLATE_INFO:
      return {
        ...state,
        currentLicensePlate: payload,
      };
    case CLEAR_CURRENT_LICENSE_PLATE:
      return {
        ...state,
        currentLicensePlate: null,
      };
    default:
      return state;
  }
};

export default currentLicensePlateReducer;
