// Action Types
export const SET_CAR = 'SET_CAR';
export const CLEAR_CAR = 'CLEAR_CAR';

const initialState = {
  car: null,
};

const currentCarReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_CAR:
      return {
        ...state,
        car: payload,
      };
    case CLEAR_CAR:
      return {
        ...state,
        car: null,
      };
    default:
      return state;
  }
};

export default currentCarReducer;
