// Action Types
export const SET_LISTINGTYPE = 'SET_LISTINGTYPE';
export const CLEAR_LISTINGTYPE = 'CLEAR_LISTINGTYPE';

const initialState = {
  listingType: 0,
};

const listingTypeReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_LISTINGTYPE:
      return {
        ...state,
        listingType: payload,
      };
    case CLEAR_LISTINGTYPE:
      return {
        ...state,
        listingType: null,
      };
    default:
      return state;
  }
};

export default listingTypeReducer;
