import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
import { createStripeAccountSession } from '../../ducks/user.duck';

export const ASSET_NAME = 'stripe-dashboard';

export const loadData = () => async dispatch => {
  const pageAsset = { stripeDashboardPage: `content/pages/${ASSET_NAME}.json` };
  dispatch(createStripeAccountSession());
  dispatch(fetchPageAssets(pageAsset, true));
};
